/* You can add global styles to this file, and also import other style files */
@import './styles/variables.scss';
@import './styles/buttons.scss';
@import './styles/mediaQuery.scss';

* {
    font-family: $montserrat-font-family;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
    margin-bottom: 0px;
}

p {
    margin-bottom: 0px;
}

//home page carousel
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: black !important;
    border-radius: 50px;
    width: 8px;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    width: 8px;
    border-radius: 50px;
}

.p-password {
    width: 100%;
}

.p-dropdown {
    border-radius: 12px;
}

.p-inputtext {
    padding: 18px 20px;
}

.p-dialog .p-dialog-content {
    padding: 0px;
}

.popup-modal-name-stat {
    padding: 0px 20px 24px 24px;

    p {
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #252525;
    }

    img {
        width: 102px;
        vertical-align: text-top;
    }
}


//
.align-item-center {
    align-items: center;
}

.gig-description {
    border: 1px solid $border-color;
    border-radius: 12px;
    padding: 16px 24px 0px 24px;

    .ql-toolbar {
        width: fit-content;
        padding: 12px 24px;
        border-radius: 12px;
        background-color: #EFEFEF;
        border: none;
    }

    .ql-container {
        border: none;
        min-height: 280px;
    }

}

label {
    font-size: 14px;
    font-weight: 500;
    color: $text-color;
    margin-bottom: 8px;
}

label.label-secondary {
    font-size: 18px !important;
    color: $header-color;
}

small,
.small {
    font-weight: 300;
    font-size: 14px;
    color: $text-color;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

input {
    border-radius: 12px;
    background: white;
    border: 0.75px solid $input-border-color;
    font-family: $montserrat-font-family;
    font-size: 14px;
    font-weight: 400;
    padding: 16px 22px;
    color: #232323;
    width: 100%;
}

input.register {
    border-radius: 12px;
    background: white;
    border: 1px solid $border-color;
    font-family: $montserrat-font-family;
    font-size: 14px;
    font-weight: 400;
    padding: 18px 24px;
    color: #232323;
}

input.secondary {
    border-radius: 0px;
    background: white;
    border: none;
    border-bottom: 1px solid $border-color;
    font-family: $montserrat-font-family;
    font-size: 14px;
    font-weight: 400;
    padding: 16px 20px;
}

.text-area {
    border-radius: 12px;
    border: 1px solid $border-color;
    padding: 16px;
    width: 100%;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    font-weight: 300;
    font-size: 16px;
    color: #484844;
}

.p-dropdown .p-dropdown-label {
    font-weight: 300;
    font-size: 16px;
    color: #484844;
}

p.paragraph {
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    color: $text-color;
}

p.paragraph-primary {
    font-size: 14px;
    line-height: 150%;
    color: $text-color;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #252525;
    background: #252525;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #252525;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0 #252525;
    border-color: #252525;
}

.p-inputswitch .p-inputswitch-slider {
    background: #d1d5db;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
    border-radius: 30px;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0 #0B0F4D;
}

.tooltip-icon-wrapper {
    position: relative;

    img {
        position: absolute;
        top: -7px;
        left: 5px;
    }
}

.text-justify {
    text-align: justify;
}

ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0px;
}

.text-right {
    text-align: right;
}

.font-weight-500 {
    font-weight: 500;
}

.w-100 {
    width: 100%;
}

.w-90 {
    width: 90%;
}

.w-60 {
    width: 60%;
}

.w-40 {
    width: 40%;
}

.w-20 {
    width: 20%;
}

.w-fit {
    width: fit-content;
}

//paddings
.pt-7 {
    padding-top: 7rem;
}

.pt-8 {
    padding-top: 8rem;
}

.pt-9 {
    padding-top: 9rem;
}

.pt-10 {
    padding-top: 10rem;
}

//margins
.mt-5 {
    margin-top: 5rem;
}

.mt-6 {
    margin-top: 6rem;
}

.mt-7 {
    margin-top: 7rem;
}

.mt-8 {
    margin-top: 8rem;
}

.mt-9 {
    margin-top: 9rem;
}

.mt-10 {
    margin-top: 10rem;
}

.mb-0 {
    margin-bottom: 0px;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mb-3 {
    margin-bottom: 3rem !important;
}

.mb-4 {
    margin-bottom: 4rem !important;
}

.mb-5 {
    margin-bottom: 5rem !important;
}

.mb-6 {
    margin-bottom: 6rem !important;
}

.mb-7 {
    margin-bottom: 7rem;
}

.mb-8 {
    margin-bottom: 8rem;
}

.education-list {
    display: flex;
    gap: 12px;
    flex-direction: row;
    flex-wrap: wrap;

    .added-education-main-wrapper {
        padding: 20px 28px;
        background-color: #efefef;
        border-radius: 12px;

        .education-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            gap: 49px;
        }

        .education-details h3 {
            font-weight: 500;
            font-size: 18px;
            color: #252525;
            margin-bottom: 8px;
        }

        .education-details h5 {
            font-weight: 300;
            font-size: 18px;
            color: #252525;
            margin-bottom: 8px;
        }
    }

    .add-another-education-wrapper {
        border-radius: 12px;
        padding: 20px 95px;
        text-align: center;
        background: #efefef;

        h3 {
            font-weight: 500;
            font-size: 18px;
            color: #252525;
        }

        img {
            margin-bottom: 8px;
        }
    }


}

.added-languages-list {
    .language-added-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 31px;
        margin-bottom: 10px;

        h4 {
            font-weight: 300 !important;
            font-size: 18px;
            color: #252525;
        }
    }

}

.added-skill-list {
    .added-skills {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 31px;
        margin-bottom: 10px;

        h4 {
            font-weight: 300 !important;
            font-size: 18px;
            color: #252525;
        }
    }

}

.slill-main-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;

    .skill-wrapper-first {
        min-width: 244px;
    }
}

.radio-wrapper {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
}

.radio-container {
    display: flex;
    align-items: center;
    position: relative;
}

input[type="radio"] {
    opacity: 0;
    width: 0;
    height: 0;
}

.radio-label {
    display: inline-block;
    padding: 8px 12px;
    border-radius: 12px;
    background: white;
    border: 1px solid $border-color;
    font-family: $montserrat-font-family;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 129px;
    box-sizing: border-box;
    text-align: center;
    color: $text-color;
}

input[type="radio"]:checked+.radio-label {
    background: $secondary-color;
    color: white;
}

.p-dropdown-panel {
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    padding: 8px;
    margin-top: 10px;
}

.p-dropdown-items-wrapper {

    .p-highlight,
    .p-focus {
        background: rgba(33, 33, 33, 0.1);
        border-radius: 8px;
        padding: 10px 16px;
        margin-right: 12px;
        font-weight: 400 !important;
        font-size: 16px;
        color: #252525;
    }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background: rgba(33, 33, 33, 0.1);
    border-radius: 8px;
    padding: 10px 16px;
    margin-right: 12px;
    color: #252525;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 10px 16px;
    margin-right: 12px;
    font-weight: 300;
    color: #252525;
    margin-bottom: 4px;
}

intl-telephone-input.input-field {
    width: 40% !important;

    .dropdown__actions {
        gap: 10px;
    }
}

.dropdown__item {
    margin-left: 10px !important;
    gap: 10px !important;
}

.flag-container {
    min-width: unset !important;
}

.phone-number {
    .input-container {
        border-radius: 12px;
        background: white;
        border: 1px solid $input-border-color !important;
        font-family: $montserrat-font-family;
        font-size: 14px;
        font-weight: 400;

        color: #232323;
        width: 100%;
        height: auto !important;

        .dropdown {
            padding: 0px 20px 0px 20px;
        }

        // .dorpdown__icon {
        //     margin-left: 10px;
        // }

        input {
            height: auto;
            padding: 10px 22px !important;
            border-radius: 0px 12px 12px 0px;
            border-top: none;
            border-right: none;
            border-bottom: none;
            width: 100% !important;
        }

        .searchCountry__input {
            width: 100% !important;
            background-color: white !important;
            color: $text-color;
        }

        :focus-visible {
            outline: none;
        }

        .phoneInput {
            margin: 10px 0px 10px 0px;
        }
    }
}

.sub-main-title {
    font-weight: 500;
    font-size: 18px;
    color: #252525;
    margin-bottom: 8px;

    p {
        font-weight: 300;
        font-size: 16px;
        color: #252525;
    }
}

//input style with icon
.custom-input-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    border: 0.75px solid $input-border-color;
    border-radius: 12px;
    padding: 0px 0px 0px 16px;

    input {
        border: none;
        padding: 16px 16px;
        font-weight: 300;
        font-size: 16px;
        color: $border-color;
    }

    .custom-input-group-prepend {
        padding: 8px 16px 9px 0px;
        border-right: 0.75px solid $input-border-color;

        .custom-input-group-text {
            font-weight: 300;
            font-size: 16px;
            color: $border-color;
        }
    }
}


//phone input field
.phone-number-country-wrapper div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-right: 1px solid rgba(33, 33, 33, 0.5);
    padding: 8px 24px 8px 0px;
}

.phone-number-country-wrapper {
    padding: 0px 0px 0px 24px;
    background: white;
    border-radius: 12px 0px 0px 12px;
    border: 1px solid $border-color;
    border-right: none;
}

.phone-number-input input {
    border-radius: 0px 12px 12px 0px;
    border-left: none;
    border-width: 1px;
    border-color: $border-color;
    width: 250px;
}

.phone-number-country-wrapper h6 {
    font-weight: 300;
    font-size: 16px;
}

//tab styles
.tab-view-main-wrapper {
    margin-top: 32px;

    ul {
        gap: 28px;

        li.p-highlight {
            a {
                font-weight: 500;
                font-size: 16px;
                color: #252525;
                text-decoration: none;
                border-bottom: 1px solid $border-color !important;
            }
        }
    }

    a {
        padding: 0px 0px 11px 0px;
        font-weight: 300;
        font-size: 16px;
        color: #252525;
        text-decoration: none;

    }

    .p-tabview-panels {
        padding: 0px;
    }
}

// table styles 
.p-datatable .p-datatable-thead>tr>th {
    background: none;
    font-weight: 500;
    font-size: 16px;
    color: #252525;
    padding-top: 0px;
    padding-bottom: 17px;
    padding-left: 14px;
}

.p-datatable .p-datatable-tbody>tr>td {
    padding: 0px;
}

.border-left-radius {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.border-right-radius {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.p-datatable .p-datatable-tbody>tr>td .table-body-text {
    padding: 16px 53px 16px 16px;
    background: $gray-color;
    font-weight: 300;
    font-size: 16px;
    color: #252525;
    margin-bottom: 8px;
    min-height: 81px;
}

.table-link-wrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.table-links {
    padding: 0px 0px 0px 0px !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    text-decoration: underline !important;
    text-decoration-skip-ink: none !important;
    text-align: center !important;
    color: $secondary-color !important;
}


.img-name-wrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: flex-start;
}

.name-wrapper {
    max-width: 124px;
    font-weight: 500;
    font-size: 16px;
    color: #252525;
}

.table-dropdown-wrapper {
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #252525;
    padding: 0px;
}

.board-main-wrapper {
    border-left: 1px solid rgba(33, 33, 33, 0.1);
    width: 80.6vw;
    min-height: 100vh;

    .board {
        display: flex;
        margin-top: 16px;
        margin-left: 18px;
        gap: 18px;
        flex-direction: row;
        overflow-x: scroll;
        height: 100vh;

        .list {
            border-radius: 12px;
            padding: 16px 16px 24px 16px;
            background-color: #f4f4f4;
            width: 220px;
            min-width: 220px;
            box-shadow: 0 4px 4px -1px rgba(12, 12, 13, 0.05), 0 4px 4px -1px rgba(12, 12, 13, 0.1);
            background: #f7f7f7;
            height: fit-content;
        }
    }
}

//radio buttons
.default-radio-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label {
        font-weight: 300;
        font-size: 16px;
        text-align: center;
        color: #252525;
    }
}

.custom-radio .p-radiobutton-box {
    width: 20px;
    height: 20px;
    border: 1px solid #212121;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.custom-radio .p-radiobutton-box .p-radiobutton-icon {
    display: none; /* Hide the default icon */
}

.custom-radio .p-radiobutton-box.p-highlight {
    background-color: white; /* Customize highlight color */
}

.custom-radio .p-radiobutton-box.p-highlight::after {
    content: '';
    background-image: url('../src/assets/images/icons/radiocheckmark.svg');
    background-size: cover;
    width: 15px;
    height: 11px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

//pills
.success-pill {
    font-weight: 600;
    font-size: 12px;
    color: #252525;
    border-radius: 12px;
    padding: 6px 16px;
    background: #9afac4;
    text-align: center;
}

.inprogress-pill {
    font-weight: 600;
    font-size: 12px;
    color: #252525;
    border-radius: 12px;
    padding: 6px 16px;
    background: #e9f791;
    text-align: center;
}

.pending-pill {
    font-weight: 600;
    font-size: 12px;
    color: #252525;
    border-radius: 12px;
    padding: 6px 16px;
    background: #ff8a8a;
    text-align: center;
}

.danger-pill {
    font-weight: 600;
    font-size: 12px;
    color: #252525;
    border-radius: 12px;
    padding: 6px 16px;
    background: #ff8a8a;
    text-align: center;
}

//media queries
@media (max-width: 576px) {

    .mt-8 {
        margin-top: 2rem;
    }

    .mt-10 {
        margin-top: 5rem;
    }

    .language-main-second,
    .skill-wrapper-second {
        overflow: hidden;

        .radio-wrapper {
            overflow: scroll;
        }
    }

    .radio-wrapper::-webkit-scrollbar {
        display: none !important;
    }

    .radio-wrapper {
        /* Safari 6.1+ */
        flex-wrap: nowrap;
        padding-bottom: 10px;
    }

    .radio-label {
        margin-bottom: 0px;
    }

    .phone-number-country-wrapper{
        min-width: 120px;
    }

    .phone-number-input input{
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width : 1023px) {
    label.label-secondary {
        font-size: 16px !important;
    }

    .language-wrapper-main {
        gap: 10px !important;
    }

    .language-main-second,
    .skill-wrapper-second {
        overflow: hidden;

        .radio-wrapper {
            gap: 10px;
        }
    }

    .radio-wrapper::-webkit-scrollbar {
        display: none !important;
    }

    .slill-main-wrapper {
        gap: 10px;
    }

    .radio-label {
        margin-bottom: 0px;
    }

    .p-datepicker table th {
        text-align: center;
    }
}

.anchor-button{
    border: none;
    background: none;
    color: #0B0F4D;
}