$primary-color:#F9BD23;
$secondary-color:#0B0F4D;

$text-color: #414141;
$header-color:#252525;
$gray-color:#EFEFEF;

$popins-font-family: "Poppins", sans-serif;
$inter-font-family: "Inter", sans-serif;
$montserrat-font-family:"Montserrat", sans-serif;

$border-color:#808080;
$input-border-color:#a0a0a0;