@use "./app/styles/variables.scss" as var;
@use "./app/styles/flags.scss";
@use "./app/styles/bank-logos.scss";
@use "tailwindcss";
@use "primeicons/primeicons.css";

@theme {
  --color-primary-blue: #0b0f4d;
  --color-primary-yellow: #f9bd23;
  --p-button-label-font-weight: 700;
}

* {
  font-family: var.$montserrat-font-family;
}

html,
body {
  margin: 0;
  padding: 0;

  button.p-button {
    color: var.$secondary-color;
  }

  header .p-menubar {
    border: none;
  }

  .p-button-label {
    font-weight: 700;
  }

  .debug {
    font-family: monospace;
    background-color: lightgray;
    border: 1px solid black;
    padding: 0.5rem;
    max-height: 40rem;
    overflow: auto;
  }

  .file-upload-container {
    .p-button {
      font-size: 0.7rem;
    }
  }
}
