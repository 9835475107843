.primary-button {
    background-color: $primary-color;
    padding: 8px 24px;
    text-decoration: none;
    border-radius: 4px;
    border: none;
    color: $secondary-color;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.01em;
}

.primary-button-desk {
    background-color: $primary-color;
    color: #0B0F4D;
    font-size: 16px;
    padding: 9px 23px;
    text-decoration: none;
    border-radius: 4px;
    border: none;
    line-height: 150%;
    color: $secondary-color;
    font-weight: 400;
    letter-spacing: 0.01em;
}

.primary-1-button {
    background-color: $secondary-color;
    color: #efefef;
    font-weight: 500;
    font-size: 18px;
    padding: 16px 0px;
    text-decoration: none;
    border-radius: 12px;
    border: 0.50px solid #717173;
    width: 100%;
}

.primary-2-button {
    background-color: rgba(33, 33, 33, 0.1);
    color: $text-color;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2px;
    padding: 15px 0px;
    text-decoration: none;
    border-radius: 12px;
    border: none;
    width: 100%;
    line-height: 150%;

    &:disabled{
        color: rgba(33, 33, 33, 0.5);
    }
}

.primary-3-button {
    background-color: $secondary-color;
    color: #efefef;
    font-weight: 400;
    font-size: 18px;
    padding: 10.5px 0px;
    text-decoration: none;
    border-radius: 12px;
    border: 0.50px solid #717173;
    // width: 100%;
}

.back-button {
    background-color: unset;
    color: $text-color;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2px;
    padding: 15px 23px 15px 0px;
    text-decoration: none;
    border-radius: 12px;
    border: none;
    width: fit-content;
    line-height: 150%;
    letter-spacing: 0.2px;
    text-align: left;

    img {
        margin-right: 15px;
    }
}

.back-button-underline {
    background-color: unset;
    color: $text-color;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.2px;
    padding: 15px 23px 15px 0px;
    text-decoration: underline;
    border: none;
    width: 100%;
    text-align: left;

    img {
        margin-right: 10px;
    }
}

.secondary-button {
    background-color: unset;
    color: $text-color;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2px;
    padding: 15px 23px 15px 23px;
    text-decoration: none;
    border-radius: 12px;
    border: 0.50px solid #b9babb;
    ;
    width: 100%;
    line-height: 150%;
    letter-spacing: 0.2px;
    text-align: center;
}

.primary-button-3-right-image {
    background-color: $primary-color;
    color: white;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.2px;
    padding: 8px 24px;
    text-decoration: none;
    border-radius: 4px;
    border: none;
    width: 100%;
    line-height: 150%;
    letter-spacing: 0.2px;
    text-align: left;
    width: fit-content;

    img {
        margin-left: 8px;
    }
}

.underline-button {
    background-color: unset;
    color: $header-color;
    border: none;
    font-weight: 500;
    font-size: 16px;
    text-decoration: underline;
    width: 100%;
    text-align: center;
}

.gray-button {
    border-radius: 12px;
    padding: 12px 28px;
    background: #efefef;
    font-weight: 500;
    font-size: 16px;
    color: #252525;
    border: none;
    width: 100%;
    text-align: center;
}

.edit-delete-button-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .delete-button-wrapper {
        border-radius: 12px 0 0 12px;
        padding: 16px 10px;
        background: #252525;
        border: none;
        display: flex;
    }

    .underline-button {
        font-weight: 500;
        font-size: 14px;
        text-decoration: underline;
        text-align: center;
        color: #252525;
        border-radius: 0 12px 12px 0;
        padding: 16px 10px;
        background-color: white !important;
    }
}

.edit-delete-2-button-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .delete-button-wrapper {
        border-radius: 12px 0 0 12px;
        padding: 8px 16px;
        background: #252525;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .underline-button {
        font-weight: 500;
        font-size: 14px;
        text-decoration: underline;
        text-align: center;
        color: #252525;
        border-radius: 0 12px 12px 0;
        padding: 8px 10px;
        background-color: white !important;
        width: fit-content;
        border: 1px solid black;
        border-bottom: 1px solid $text-color;
        border-right: 1px solid $text-color;
        border-top: 1px solid $text-color;
    }
}

.verticle-dot-button {
    border: none;
    background: none;
}