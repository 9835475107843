// Extra small devices (phones, 576px and down)
@media (max-width: 767px) {
    // Your styles for extra small devices here
    .hide-in-mobile{
        display: none !important;
    }

    .mobile-text-align-center{
      text-align: center;
    }
  }
  
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) and (max-width:991px) {
    // Your styles for medium devices here
    .hide-in-tablet{
      display: none !important;
    }
  }
  
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    .hide-in-desktop{
      display: none !important;
  }
    // Your styles for large devices here
    
  }
  
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    // Your styles for extra large devices here
  }